// import AnimatedRoutes from "./components/AnimatedRoutes";
import { Routes, Route, useLocation } from "react-router-dom";

import Header from "./components/Header";
import Landing from "./pages/landing/Landing";
import About from "./pages/about/About";
import Portfolio from "./pages/portfolio/Portfolio";
import Contact from "./pages/contact/Contact";

function App() {

  const location = useLocation()

  const personalDetails = {
    name: "Joe Freeman",
    location: "London, UK",
    tagline: "Jr Developer, Explorer, Dog Lover",
    email: "joefreeman8@gmail.com",
    availability: "Open for work",
    brand: `
    Hey there! 🚀  I'm a Jr Software Developer on a mission to turn caffeine induced headaches into code. Passionate about learning, problem-solving, and crafting awesome websites, I conquered a Software Engineering Bootcamp with General Assembly and since completion I've been having a blast as an Instructor Assistant for them, honing my bug-terminating skills. 🧨 🪲 🔫
    
    Now, in the wild world of 2024, I'm eagerly seeking a team where I can be the new guy who dives head first into coding conundrums. I'm excited to continue my developer journey, get my hands dirty squashing bugs (possibly spilling coffee in the process), and, of course, provide top-notch dog chat. Let's build something awesome together!
  `
  }

  return (
    <>
      <Header />
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Landing name={personalDetails.name} tagline={personalDetails.tagline} />} />
        <Route
          path="/about"
          element={
            <About
              name={personalDetails.name}
              location={personalDetails.location}
              email={personalDetails.email}
              availability={personalDetails.availability}
              brand={personalDetails.brand}

            />
          }
        />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route
          path="/contact"
          element={
            <Contact name={personalDetails.name} location={personalDetails.location} email={personalDetails.email} />
          }
        />
      </Routes>
    </>
  );
}

export default App;
